<template>
  <section class="services my-5 py-5">
    <!-- <main-jumbotron /> -->

    <card-group
      :isHome="false"
      class="mt-5"
      :group-title="$t('title.all_services')"
      :services="templateServices"
      :all-services="true"
    />

    <subscribe />
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Services",
  components: {
    CardGroup: () => import("@/components/Services/CardGroup"),
    Subscribe: () => import("@/components/Subscribe")
  },
  computed: {
    ...mapGetters(["GlobalServices"]),
    templateServices() {
      let templateArray = [];
      this.GlobalServices.forEach(service => {
        templateArray.push({
          id: service.id,
          title: service.name,
          description: service.short_description,
          image: service.main_image
        });
      });

      return templateArray;
    }
  }
};
</script>
